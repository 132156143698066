import { Button, Image, Input, Spinner, Textarea } from "@nextui-org/react";
import Header from "./Header";
import { FaArrowRight } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

const api = `https://vladmiva.dvg-project.com`;

type FetchStage = "start" | "end" | "wait" | "error";

const App = () => {
  const [isLoading, setIsLoading] = useState<FetchStage>("wait");
  const [isEndLoading, setIsEndLoading] = useState<FetchStage>("wait");
  const [startImg, setStartImg] = useState("");
  const [endImg, setEndImg] = useState<string | null>(null);
  const [result, setResult] = useState<number | null>(null);
  const file = useRef<any>(null);

  useEffect(() => {
    get();
  }, []);
  useEffect(() => {
    console.log(file.current);
  }, [file.current]);

  const get = async () => {
    try {
      setIsLoading("start");
      const { data } = await axios.get<{ photo: string }>(
        `${api}/api/get_example/`
      );
      setStartImg(`data:image/jpeg;base64, ${data.photo}`);
      setIsLoading("end");
    } catch (e) {
      console.log(e);
      setIsLoading("error");
    }
  };

  const check = async () => {
    try {
      const form = new FormData();
      if (!file.current) return;
      form.append(`img`, file.current);
      setIsEndLoading("start");
      const { data } = await axios.post<{ diff_precent: number }>(
        `${api}/api/compare_img/`,
        form
      );
      setResult(data.diff_precent);
      setIsEndLoading("end");
    } catch (e) {
      setIsEndLoading("error");
    }
  };

  return (
    <div className="container mx-auto">
      <Header />
      <div className="w-full py-10 flex justify-center gap-20 items-center">
        {isLoading === "start" ? (
          <Spinner />
        ) : isLoading === "error" ? (
          <p className="text-red-500">
            Прозошла ошибка, мы её оперативно решаем
          </p>
        ) : (
          <Image
            width={300}
            className="rounded-md"
            alt="Logoimg"
            src={startImg}
          />
        )}
        <div className="flex justify-center flex-col items-center gap-5">
          <FaArrowRight className="mr-2" />
          <Button
            isLoading={isEndLoading === "start"}
            onClick={check}
            size="sm"
            color="primary"
          >
            Сравнить
          </Button>
          {endImg && (
            <Button
              onClick={() => {
                setResult(null);
                setEndImg(null);
              }}
              size="sm"
              color="secondary"
            >
              Очистить
            </Button>
          )}
        </div>
        <div>
          {endImg ? (
            <Image width={300} src={endImg} />
          ) : (
            <div className="flex flex-col gap-5">
              <label htmlFor="files">Выбрать изображение</label>
              <input
                id="files"
                type="file"
                onChange={(e) => {
                  if (e?.target?.files && e?.target?.files.length > 0) {
                    console.log(e.target.files[0]);
                    file.current = e.target.files[0];
                    var reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = function () {
                      if (typeof reader.result === "string") {
                        setEndImg(reader.result);
                      }
                    };
                  } else {
                    console.log("except");
                  }
                }}
                className="max-w-52"
                placeholder="Выберите файл для сравнения"
              />
            </div>
          )}
        </div>

        {/* <Image width={300} className="rounded-md" alt="Logoimg" src={Start} /> */}
      </div>
      <Textarea
        isReadOnly
        label={
          <div className="flex gap-5">
            <p>Результат</p>
            {isEndLoading === "start" && <Spinner size="sm" />}
          </div>
        }
        labelPlacement="outside"
        placeholder={
          result || result === 0
            ? `Разница: ${result}%`
            : "Загрузите картинку и нажмите сравнить чтобы вычислить несоответствия и вывести результат"
        }
        className="max-w-xs mx-auto"
      />
    </div>
  );
};

export default App;
